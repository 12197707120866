<template>
  <data-table
    ref="table"
    :actions="isUserInternalAdmin ? actions : []"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import mixin from './mixin'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapGetters: mapToolsGetters } = createNamespacedHelpers('tools')

export default {
  name: 'Projects',
  mixins: [mixin],
  data() {
    return {
      actions: [
        {
          event: 'internal/onEditProject',
          name: 'Edit',
        },
        {
          event: 'internal/onDeleteProject',
          name: 'Delete',
        },
      ],
    }
  },
  computed: {
    ...mapAuthGetters(['isUserInternalAdmin']),
    ...mapToolsGetters([
      'projects',
    ]),
  },
}
</script>
